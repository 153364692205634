var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),(_vm.uploadApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.uploadApi}}):_vm._e(),(_vm.showXP)?_c('XPDialog',{attrs:{"callbackClose":() => (_vm.showXP = false),"showDialog":true,"details":_vm.xpDetails}}):_vm._e(),_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("title.studio"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',[_c('div',{staticClass:"shop-panel"},[_c('v-img',{staticClass:"ms-2 d-flex justify-center align-end text-center text-stroke-md",staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/explore/menu/gallery.png'),"max-width":"80"},on:{"click":function($event){return _vm.gallery()}}},[_vm._v(" "+_vm._s(_vm.$t("title.gallery"))+" ")])],1),_c('div',{staticClass:"medal-wrapper"},[_c('div',{staticClass:"medal-panel d-flex justify-end align-center text-h6",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.art()}}},[_vm._v(" "+_vm._s(_vm.awards)+" "),_c('v-img',{staticClass:"ms-2",attrs:{"src":require('@/assets/explore/goldenbrush.png'),"max-width":"30"}})],1)]),_c('v-img',{staticClass:"d-flex align-center mx-auto",attrs:{"src":require('@/assets/explore/studio.png'),"contain":""}},[(_vm.uploadApi.isLoading || _vm.removeApi.isLoading)?_c('div',{staticClass:"text-center ma-5",staticStyle:{"right":"24%","top":"55px","position":"absolute"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_c('div',{staticStyle:{"right":"17%","top":"55px","position":"absolute"}},[(
                  _vm.drawing &&
                  _vm.drawing.GalleryAward &&
                  !_vm.api.isLoading &&
                  !_vm.uploadApi.isLoading &&
                  !_vm.removeApi.isLoading
                )?_c('div',{staticStyle:{"position":"absolute","z-index":"9"}},[_c('span',{staticClass:"d-flex"},_vm._l((_vm.drawing.awardCount),function(i){return _c('span',{key:i},[_c('v-img',{staticClass:"d-flex justify-center align-end",class:'mr-' + i,attrs:{"contain":"","max-width":"30","src":_vm.drawing.GalleryAward.imageUrl}})],1)}),0)]):_vm._e(),(
                  !_vm.api.isLoading &&
                  !_vm.uploadApi.isLoading &&
                  !_vm.removeApi.isLoading
                )?_c('v-img',{staticStyle:{"width":"28vw","height":"28vw","max-height":"134px","max-width":"134px"},style:(_vm.currentImageUrl || _vm.imageUrl ? 'cursor: pointer;' : ''),attrs:{"src":_vm.imageUrl ? _vm.imageUrl : _vm.currentImageUrl,"contain":""},on:{"click":function($event){_vm.currentImageUrl || _vm.imageUrl ? _vm.viewImage() : null}}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center mx-auto"},[_c('AbstractAvatar',{staticClass:"ms-8",attrs:{"avatar":_vm.studentAvatar,"height":180,"width":180}})],1)]),_c('div',{staticClass:"text-left py-1 d-flex justify-space-around align-center",staticStyle:{"height":"50px","margin-top":"-70px"}},[_c('div',[_c('v-img',{staticClass:"ms-2 d-flex justify-center align-end text-center text-stroke-black-sm",class:_vm.imageUrl || _vm.currentImageUrl ? null : 'disabled-item-image',staticStyle:{"overflow":"visible"},style:(_vm.imageUrl || _vm.currentImageUrl ? 'cursor: pointer' : ''),attrs:{"src":require('@/assets/explore/bin.png'),"max-width":"55"},on:{"click":_vm.openDialog}},[_vm._v(" "+_vm._s(_vm.$t("action.remove"))+" ")])],1),_c('div',[_c('v-img',{staticClass:"ms-2 d-flex justify-center align-end text-center text-stroke-black-sm",class:!_vm.theme || _vm.api.isLoading || _vm.endTime != undefined
                    ? 'disabled-item-image'
                    : null,staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/explore/paint.png'),"max-width":"90"},on:{"click":_vm.openFileInput}},[_vm._v(" "+_vm._s(_vm.$t("action.draw"))+" ")]),(_vm.endTime == undefined)?_c('div',{staticClass:"text-caption my-1"},[_vm._v(" "+_vm._s(_vm.$t("string.upload_art"))+" ")]):_c('CountDown',{attrs:{"endTime":_vm.endTime,"message":_vm.$t('string.resetIn')}})],1)]),_c('div',{staticStyle:{"height":"50px","margin-top":"-50px","margin-bottom":"30px"}},[_c('v-file-input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"accept":"image/*"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),(_vm.theme && _vm.api.isLoading != true)?_c('div',{staticClass:"items-panel pa-3 mt-12"},[_c('div',{staticClass:"text-stroke-md text-center text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.month_theme"))+" ")]),_c('div',{staticClass:"text-stroke-black-md text-center text-h4"},[_vm._v(" "+_vm._s(_vm.theme.name)+" ")]),_c('div',{staticClass:"pt-3"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":_vm.theme.imageUrl,"contain":"","max-height":"200","max-width":"300"}})],1)]):_vm._e()],1),(_vm.isView)?_c('v-card',{staticClass:"save-confirm"},[_c('div',{staticClass:"view-image mx-auto"},[_c('div',{staticClass:"text-right pa-3"},[_c('CloseBtn',{attrs:{"size":"40","callback":_vm.closeView}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"max-height":"70vh","width":"100%"}},[_c('v-img',{staticClass:"mx-auto",staticStyle:{"max-height":"70vh","width":"100%"},attrs:{"src":_vm.imageUrl ? _vm.imageUrl : _vm.currentImageUrl,"contain":""}}),_c('div',{staticClass:"mx-auto mt-3 d-flex",staticStyle:{"z-index":"1 !important","background-color":"white","max-width":"250px"}},[(_vm.drawing.awardCount)?_c('div',{staticClass:"d-flex justify-center pa-1"},_vm._l((_vm.drawing.awardCount),function(i){return _c('div',{key:i},[(
                          _vm.drawing.GalleryAward &&
                          _vm.drawing.GalleryAward.imageUrl
                        )?_c('v-img',{attrs:{"contain":"","max-width":"30","src":_vm.drawing.GalleryAward.imageUrl}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"font-weight-black text-center pt-2"},[_vm._v(" "+_vm._s(_vm.transformName(_vm.auth.User.name))+" ")]),(_vm.auth.Institution)?_c('div',{staticClass:"text-subtitle-2 text-center text--secondary"},[_vm._v(" "+_vm._s(_vm.auth.Institution.name)),_c('br'),_vm._v(_vm._s(_vm.auth.Classroom.name)+" ")]):_vm._e()])])],1)])])]):_vm._e()],1),_c('v-dialog',{staticClass:"rounded-lg",attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',{attrs:{"width":"","elevation":"1","outlined":"","rounded":"lg"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"30","callback":() => {
                  _vm.confirmDialog = false;
                }}})],1),_c('v-card-text',{staticClass:"mb-3"},[_c('div',{staticClass:"text-center red lighten-4 rounded-lg py-3 px-3"},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t("string.are_you_sure")))])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-btn',{staticClass:"text-h6 px-4",attrs:{"loading":_vm.removeApi.isLoading,"dark":"","color":"red"},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t("action.remove"))+" ")])],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }